<template>
   <section class="cont">
      <el-row class="content-box">
         <el-row>
            <el-row><h6 class="font-24">城市分布分析</h6></el-row>
            <el-row class="d-flex">
               <el-col :span="12">
                  <div style="height: 400px; width: 100%" ref="echart1"></div>
               </el-col>
               <el-col :span="12">
                  <div style="height: 400px; width: 100%" ref="echart2"></div>
               </el-col>
            </el-row>
         </el-row>
         <el-row class="table-box">
            <el-row><h6 class="font-24">客户来源分析</h6></el-row>
            <el-table :data="sourceData" border :stripe="true">
               <el-table-column prop="source" label="来源"></el-table-column>
               <el-table-column prop="APP" label="APP"></el-table-column>
               <el-table-column prop="APPLETS" label="小程序"></el-table-column>
               <el-table-column prop="DOUYIN" label="抖音"></el-table-column>
               <el-table-column prop="EMAIL" label="邮件"></el-table-column>
               <el-table-column prop="PLATFORM" label="平台"></el-table-column>
               <el-table-column prop="PMS" label="PMS"></el-table-column>
               <el-table-column prop="DINGTALK" label="钉钉"></el-table-column>
               <el-table-column prop="QQ" label="QQ"></el-table-column>
               <el-table-column prop="SMS" label="短信"></el-table-column>
               <el-table-column prop="SSM" label="自助机"></el-table-column>
               <el-table-column prop="WEB" label="网站"></el-table-column>
               <el-table-column prop="WECHAT_APPLET" label="微信小程序"></el-table-column>
               <el-table-column prop="WECHAT_OFFICIAL" label="微信公众号"></el-table-column>
               <el-table-column prop="XIAOHONGSHU" label="小红书"></el-table-column>
            </el-table>
         </el-row>
         <el-row class="table-box">
            <el-row><h6 class="font-24">客户行业分析</h6></el-row>
            <el-table :data="industryData" border fit :stripe="true">
               <el-table-column prop="source" label="客户行业" min-width="110" fixed="left"></el-table-column>
               <el-table-column prop="互联网" label="互联网"></el-table-column>
               <el-table-column prop="会计" label="会计"></el-table-column>
               <el-table-column prop="其他" label="其他"></el-table-column>
               <el-table-column prop="制药" label="制药"></el-table-column>
               <el-table-column prop="制造" label="制造"></el-table-column>
               <el-table-column prop="医疗" label="医疗"></el-table-column>
               <el-table-column prop="原材料" label="原材料"></el-table-column>
               <el-table-column prop="培训" label="培训"></el-table-column>
               <el-table-column prop="媒体" label="媒体"></el-table-column>
               <el-table-column prop="广告" label="广告"></el-table-column>
               <el-table-column prop="建筑" label="建筑"></el-table-column>
               <el-table-column prop="房地产" label="房地产"></el-table-column>
               <el-table-column prop="政府" label="政府"></el-table-column>
               <el-table-column prop="教育" label="教育"></el-table-column>
               <el-table-column prop="服务业" label="服务业"></el-table-column>
               <el-table-column prop="物流" label="物流"></el-table-column>
               <el-table-column prop="能源" label="能源"></el-table-column>
               <el-table-column prop="计算机" label="计算机"></el-table-column>
               <el-table-column prop="运输" label="运输"></el-table-column>
               <el-table-column prop="银行" label="银行"></el-table-column>
            </el-table>
         </el-row>
         <el-row class="user-num">
            <el-col :span="12" class="user-bg" style="padding-right: 5px">
               <el-row class="user-item">
                  <el-row class="user-head">
                     <p class="user-title">完成当日会员任务的会员数</p>
                     <el-row class="user-flex">
                        <p class="m-right-10">过去7日，按日，所有用户</p>
                        <el-date-picker
                              v-model="statisticsDay2"
                              align="right"
                              type="date"
                              placeholder="选择日期"
                              value-format="yyyy-MM-dd"
                              :picker-options="dayPickerOptions">
                        </el-date-picker>
                     </el-row>
                  </el-row>
                  <el-row class="user-table">
                     <el-table :data="taskData" border style="width: 100%" height="200">
                        <el-table-column prop="statisticsDay" label="日期"></el-table-column>
                        <el-table-column prop="taskNum" label="积分任务的次数"></el-table-column>
                     </el-table>
                  </el-row>
               </el-row>
            </el-col>
            <el-col :span="12" class="user-bg" style="padding-left: 5px">
               <el-row class="user-item">
                  <el-row class="user-head">
                     <p class="user-title">注册时间</p>
                     <el-row class="user-flex">
                        <p class="m-right-10">过去7日，按日，所有用户</p>
                        <el-date-picker
                              v-model="statisticsDay3"
                              align="right"
                              type="date"
                              placeholder="选择日期"
                              value-format="yyyy-MM-dd"
                              :picker-options="dayPickerOptions">
                        </el-date-picker>
                     </el-row>
                  </el-row>
                  <el-row class="user-table">
                     <el-table :data="registerData" border style="width: 100%" height="200">
                        <el-table-column prop="statisticsDay" label="日期"></el-table-column>
                        <el-table-column prop="userNum" label="会员的用户数"></el-table-column>
                     </el-table>
                  </el-row>
               </el-row>
            </el-col>
         </el-row>
      </el-row>
   </section>
</template>

<script>
import { urlObj } from '@/api/interface'
import { mapState } from 'vuex'
export default {
   name: "echarts",
   props: ["userJson"],
   data() {
      return{
         chart: null,
         hotelId: '',
         statisticsDay2: '',
         statisticsDay3: '',
         limit: 7,             // 每页数
         page: 1,              // 当前页
         hotelList: [
            { id: 1, hotelName: '丰润达大酒店' }
         ],   // 酒店列表
         sourceData: [],
         industryData: [],
         taskData: [
            { statisticsDay: '2021-02-14', taskNum: 860 },
            { statisticsDay: '2021-02-15', taskNum: 921 },
            { statisticsDay: '2021-02-16', taskNum: 831 },
            { statisticsDay: '2021-02-17', taskNum: 789 },
         ],
         registerData: [
            { statisticsDay: '2021-02-14', userNum: 39 },
            { statisticsDay: '2021-02-15', userNum: 61 },
            { statisticsDay: '2021-02-16', userNum: 41 },
            { statisticsDay: '2021-02-17', userNum: 19 },
         ],
         dayPickerOptions: {
            disabledDate(time) {
               return time.getTime() > Date.now();
            },
            shortcuts: [{
               text: '今天',
               onClick(picker) {
                  picker.$emit('pick', new Date());
               }
            }, {
               text: '昨天',
               onClick(picker) {
                  const date = new Date();
                  date.setTime(date.getTime() - 3600 * 1000 * 24);
                  picker.$emit('pick', date);
               }
            }, {
               text: '一周前',
               onClick(picker) {
                  const date = new Date();
                  date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', date);
               }
            }]
         },
         mapOption1: {
            title: {
               text: '全部客户',
               subtext: '',
               sublink: 'http://zh.wikipedia.org/wiki/%E9%A6%99%E6%B8%AF%E8%A1%8C%E6%94%BF%E5%8D%80%E5%8A%83#cite_note-12'
            },
            tooltip: {
               trigger: 'item',
               formatter: '{b}<br/>{c} (p / km2)'
            },
            toolbox: {
               show: true,
               orient: 'vertical',
               left: 'right',
               top: 'center',
               feature: {
                  dataView: {readOnly: false},
                  restore: {},
                  saveAsImage: {}
               }
            },
            visualMap: {
               min: 50,
               max: 500,
               text: ['High', 'Low'],
               realtime: false,
               calculable: true,
               inRange: {
                  color: ['lightskyblue', 'yellow', 'orangered']
               }
            },
            series: [
               {
                  name: '会员各省分布情况',
                  type: 'map',
                  mapType: 'china', // 自定义扩展图表类型
                  label: {
                     show: true
                  },
                  data: [],
               }
            ]
         },
         mapOption2: {
            title: {
               text: '成交客户',
               subtext: '',
               sublink: 'http://zh.wikipedia.org/wiki/%E9%A6%99%E6%B8%AF%E8%A1%8C%E6%94%BF%E5%8D%80%E5%8A%83#cite_note-12'
            },
            tooltip: {
               trigger: 'item',
               formatter: '{b}<br/>{c} (p / km2)'
            },
            toolbox: {
               show: true,
               orient: 'vertical',
               left: 'right',
               top: 'center',
               feature: {
                  dataView: {readOnly: false},
                  restore: {},
                  saveAsImage: {}
               }
            },
            visualMap: {
               min: 5,
               max: 50,
               text: ['High', 'Low'],
               realtime: false,
               calculable: true,
               inRange: {
                  color: ['lightskyblue', 'yellow', 'orangered']
               }
            },
            series: [
               {
                  name: '会员消费分布情况',
                  type: 'map',
                  mapType: 'china', // 自定义扩展图表类型
                  label: {
                     show: true
                  },
                  data: [],
               }
            ]
         }
      }
   },
   beforeCreate() {
      const { accountType } = JSON.parse(sessionStorage.getItem('userInfo'))
      if (accountType === 'HOTEL') this.$router.push('/hotel_index')
   },
   mounted() {
      this.getHotels()
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   beforeDestroy() {
      if (!this.chart) {
         return;
      }
      this.chart.dispose();
      this.chart = null;
   },
   methods: {
     // 获取酒店ID
      getHotels(){
         const url = urlObj.getHotelPage + `?limit=1&page=1`
         const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
         const param = { hotelId: userInfo.extra && userInfo.extra.hotelId || '', companyId: userInfo.storeId || '' }
         this.$axios.post(url, param, 'json').then(res => {
           if (res.success) {
             let hotelList = res.records
             this.$store.dispatch('setHotelInfo', hotelList[0])
             sessionStorage.setItem('hotelInfo', JSON.stringify(hotelList[0]))
             this.hotelId = hotelList[0].id
             this.getAllCustomersMap()
             this.getDealCustomersMap()
              this.getMemberChannel()
              this.getMemberIndustry()
           }
         })
       },
      // 获取全部客户的地图
      getAllCustomersMap(){
         let myChart = this.$echarts.init(this.$refs.echart1); // 这里是为了获得容器所在位置
         window.onresize = myChart.resize;
         const url = urlObj.memberByProvince
         const param = { hotelId: this.hotelInfo.id || this.hotelId }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               let data = res.records
               this.mapOption1.series[0].data = data.map(item => {
                  return { name: item.province, value: item.totalCount }
               })
               myChart.setOption(this.mapOption1)
            }
         })
      },
      // 获取成交客户的分布地图
      getDealCustomersMap(){
         let myChart = this.$echarts.init(this.$refs.echart2); // 这里是为了获得容器所在位置
         window.onresize = myChart.resize;
         const url = urlObj.memberByConsume
         const param = { hotelId: this.hotelInfo.id || this.hotelId }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               let data = res.records
               this.mapOption2.series[0].data = data.map(item => {
                  return { name: item.province, value: item.totalCount }
               })
               myChart.setOption(this.mapOption2)
            }
         })
      },
      // 获取会员来源渠道
      getMemberChannel(){
         const url = urlObj.memberChannel
         const param = { companyId: this.hotelInfo.storeId }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.sourceData = []
               let data = res.records
               let all = {}
               let consume = {}
               data.all.push({userSource: 'source', count: '所有客户'})
               data.all.forEach(item => all[item.userSource] = item.count)
               data.consume.push({userSource: 'source', count: '成交客户'})
               data.consume.forEach(item => consume[item.userSource] = item.count)
               this.sourceData.push(all, consume)
            }
         })
      },
      // 获取会员所在行业
      getMemberIndustry(){
         const url = urlObj.memberIndustry
         const param = { hotelId: this.hotelInfo.id || this.hotelId }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.industryData = []
               let data = res.records
               let all = {}
               let consume = {}
               data.all.push({industry: 'source', totalCount: '所有客户'})
               data.all.forEach(item => all[item.industry] = item.totalCount)
               data.consume.push({industry: 'source', totalCount: '成交客户'})
               data.consume.forEach(item => consume[item.industry] = item.totalCount)
               this.industryData.push(all, consume)
            }
         })
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         if (newVal.id !== oldVal.id && oldVal) {
            this.getAllCustomersMap()
            this.getDealCustomersMap()
         }
      }
   }
}
</script>

<style scoped lang="scss">
.cont{
   padding: 30px;
   .content-box{
      > .el-row{
         margin-bottom: 50px;
         h6{ margin-bottom: 22px }
      }
   }
   .user-num{
      margin-top: 5px;
      .user-item{
         padding: 20px;
         background-color: #ffffff;
         .user-head p{
            line-height: 28px;
         }
         .user-head .user-title{
            font-size: 24px; font-weight: bold; color: #000000;
            margin-bottom: 23px;
         }
         .user-flex {
            display: flex;
            > p{ font-size: 18px; line-height: 40px }
         }
         .user-table{
            margin-top: 10px;
            /deep/ .el-table{
               .el-table__header th{
                  font-size: 17px; font-weight: 400;
                  background: #f4f4f4; color: #2D2D2D;
               }
            }
         }
      }
   }
}
</style>
